.change-password-box {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.change-password-card {
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 0px;
  width: 30%;
}
.change-password-form-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1%;
  margin-bottom: 20px;
}

.password-criteria-icon {
  font-size: 12px;
  margin-right: 2px;
}
