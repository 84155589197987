.sub-heading-text {
  font-size: 18px;
  font-weight: 700;
}

.d-flex-align-justify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-align {
  display: flex;
  align-items: center;
}
