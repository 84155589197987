/* #profile-card > .ant-btn {
  padding: 0px !important;
  height: auto !important;

} */

.profile-card-cls {
  margin-left: auto;
  margin-right: 5px;
  padding: 4px;
  height: auto;
}
/* .profile-card-cls:hover {
  background-color: #1677ff;
  border: 1px solid lightblue;
} */
.profile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
